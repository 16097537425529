<template>
    <div>
        <b-navbar class="maintenance-header">
            <div class="container centered">
                <b-navbar-brand>
                    <router-link :to="{ path: '/' }">
                        <img
                            src="/logo.webp"
                            alt="pescario.ro"
                            height="32"
                            width="130"
                        >
                    </router-link>
                </b-navbar-brand>
            </div>
        </b-navbar>
        <div class="container">
            <b-row>
                <b-col
                    cols="12"
                    class="maintenance"
                >
                    <div class="empty-layout">
                        <div class="container">
                            <div class="items">
                                <tool-icon
                                    v-for="index in 2"
                                    :key="index"
                                />
                            </div>
                            <div class="items">
                                <tool-icon
                                    v-for="index in 2"
                                    :key="index"
                                />
                            </div>
                        </div>
                    </div>

                    <h1>{{ $t('page.maintenance.heading') }}</h1>
                    <h3>{{ $t('page.maintenance.subHeading') }}</h3>
                    <h4>{{ $t('page.maintenance.text') }}</h4>
                </b-col>
            </b-row>
        </div>
        <div class="maintenance-footer mt-4">
            <div class="footer-copyright">
                <p class="sm">
                    © {{ year }} Pescario, All rights reserved
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    import ToolIcon from 'vue-feather-icons/icons/ToolIcon';
    export default {
        name: 'Maintenance',
        components: {
            ToolIcon,
        },
        computed: {
            year(){
                let currentDate = new Date();
                return currentDate.getFullYear();
            }
        }
    };
</script>
<style scoped lang="scss">
.maintenance {
  min-height: calc(100vh - 72px - 24px - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .maintenance-header {
    box-shadow: inset 0px -1px 0px $gray-light;
    position: fixed;
    z-index: 999;
    background: $white;
    width: 100%;
  }

  .empty-layout {
    position: absolute;
    height: calc(100% + 25px);
    width: 100%;
    .container {
      flex-wrap: wrap;
      height: 100%;
      .items {
        position: relative;
        height: 50%;
        svg {
          width: 50%;
          height: 75%;
          opacity: 0.04;
          position: absolute;
        }
        &:first-of-type {
          svg {
            &:first-of-type {
              left: -20%;
              top: -5%;
              @include media-breakpoint-down(xl) {
                left: 0%;
                top: 0%;
              }
            }
            &:last-of-type {
              right: 0;
              top: 15%;
            }
          }
        }
        &:last-of-type {
          svg {
            &:first-of-type {
              left: -5%;
              @include media-breakpoint-down(lg) {
                left: 5%;
              }
              bottom: 10%;
            }
            &:last-of-type {
              right: -10%;
              bottom: -10%;
              @include media-breakpoint-down(lg) {
                right: 0%;
                bottom: 0%;
              }
            }
          }
        }
      }
    }
  }
  h1 {
    font-size: 80px;
    letter-spacing: 1px;
    color: $primary;
    margin-bottom: 16px;
    text-align: center;
    @include media-breakpoint-between(sm, md) {
      font-size: 52px;
      max-width: 75%;
    }
    @include media-breakpoint-down(sm) {
      font-size: 40px;
      max-width: 90%;
    }
  }
  h3 {
    color: $secondary;
    text-align: center;
    @include media-breakpoint-down(md) {
      font-size: 24px;
      max-width: 75%;
    }
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      max-width: 90%;
    }
  }
  h4 {
    color: $text;
    text-align: center;
    @include media-breakpoint-down(md) {
      font-size: 18px;
      max-width: 75%;
    }
    @include media-breakpoint-down(sm) {
      font-size: 16px;
      max-width: 90%;
    }
  }

  .maintenance-footer {
    color: $gray;
    border-top: 1px solid $gray-thin;
    background-color: $white;
    .footer-copyright {
      padding: 24px 0px;
      p {
        text-align: center;
        color: $gray-dark;
      }
    }
  }
}
</style>
